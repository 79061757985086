.card-image {
    height:50px;
    width:50px;
    fill: gray;
    margin:10px;
  }

  .row {
    padding-top:20px;
    padding-bottom: 120px;
  }

  .Price {
    height: 30px; /* Adjust height as needed */
    line-height: 30px; /* Center the text vertically */
    /* Other styles for Price */
  }

  .dots3 {
    display:block;
    height: 30px; /* Adjust height as needed */
    line-height: 30px; /* Center the text vertically */
    /* Other styles for Price */
  }

  .col-md-4 {
    width: 100%; /* Full width on small screens */
    padding-bottom:20px
}

.gridimage {
  display: block;
  height: 195px;
  width: 130px
}

.form-label {
    padding-right: 10px;
}

.btn-primary {
    background-color: blue;
    border-color: blue;
    width:60px;
    height:40px;
    margin:10px;
    color:white;
    font-weight: bold;
}
  
  @media (min-width: 768px) {
    .col-md-4 {
      width: 33.3333%; /* One third of the container on medium screens and larger */
      float: left; /* Align items side by side */
      box-sizing: border-box; /* Include padding and border in the element's width */
    }
  }