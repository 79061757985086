body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-image {
  width: 400px;
  height: 617px;
  background-color: #ccc;
}

.rsvp-hero-image {
  width: 400px;
  height: 400px;
  background-color: #ccc;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  height: 100vh; /* This will take the full viewport height */
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding-top: 20px;
  border-bottom: 2px solid #000;  /* This line adds the border */
  padding-bottom: 10px;
  text-decoration:none;
}

.nav a {
  text-decoration: none;
}

.nav a.active {
  text-decoration: underline;
}
.rsvplabel {
    display: inline-block;
    width: 200px;
    text-align: right;
    padding: 5px;
}

.inputdiv {
  width: 500px;
}

.rsvpbutton {
    width: 120px;
    color: white;
    background-color: blue;
    height: 50px;
    font-weight: bold;
}

.bbabdt_home {
  font-size: xx-large;
  font-weight: bold;
  padding-bottom: 10px;
}

.bbabdt_year {
  font-size: xx-large;
  font-weight: bold;
  padding-bottom: 10px;
}

.bbabdt_theme {
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 10px;
}

.bbabdt_when{
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 10px;
}

.bbabdt_year_color {
  color:red
}

.rsvp_title {
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 10px;
}
 
.clara-image {
  width: 300px;
  height: 400px;
  background-color: #ccc;
}